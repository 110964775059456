@@ -0,0 +1,77 @@
<template>
  <v-col>
    <v-row class="wrap justify-center">
      <!-- Loop through organizations that have discrepancy data. -->
      <v-col
        v-for="report in reportOptions"
        :key="report.key"
        class="text-truncate col-auto"
      >
        <v-card
          width="320px"
        >
          <v-card-text>
            <v-col>
              <v-row>
                <v-col class="text-truncate">
                  <span
                    class="font-weight-bold primary--text cursor-pointer"
                    :title="report.text"
                    @click="selectSearchItem(report)"
                  >{{ report.text }}</span>
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col class="pt-0">
                  <span class="caption font-weight-bold">Billing Code </span>
                  <span class="caption font-weight-medium">{{ report.billingCode }}</span>
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col class="pt-0">
                  <span class="caption font-weight-bold">Network </span>
                  <span class="caption font-weight-medium">{{ report.networkCode }}</span>
                </v-col>
              </v-row>

              <v-row
                v-if="report.teamId"
                class="mt-0"
              >
                <v-col class="pt-0">
                  <span class="caption font-weight-bold">Team ID</span>
                  <span class="caption font-weight-medium pl-1">{{ report.teamId === '-1' ? 'All' : report.teamId }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapState: mapToolsState } = createNamespacedHelpers('tools')

export default {
  name: 'ReportList',
  computed: {
    ...mapToolsState({
      reportName: (state) => state.reportName,
      searchResults: (state) => state.searchResults,
    }),
    searchOptions() {
      return this.searchResults?.endpoints[this.reportName] || {}
    },
    reportOptions() {
      const { searchOptions } = this
      return (searchOptions?.items.list || []).filter((item) => item.dataType !== 'custom')
    },
  },
  methods: {
    async selectSearchItem(selection) {
      await this.$store.dispatch('tools/selectSearchItem', selection)

      return this.$store.dispatch('tools/renderMainContent', this.$route)
    },
  },
}
</script>
